import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './InscriptionOperations.css';
import init, { create_inscription, clean_inscription, hac_to_mei } from "hacash_web_api";

function InscriptionOperations() {
  const { t } = useTranslation();
  const resultRef = useRef(null);

  const [formData, setFormData] = useState({
    address: '',
    diamonds: '',
    content: '',
    protocolCost: '',
    fee: '',
    transactionTimeLimit: ''
  });

  const [result, setResult] = useState(null);
  const [operationType, setOperationType] = useState('create'); // 'create' 或 'clean'

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createdTransaction = await createTransaction(formData);
    if (createdTransaction) {
      signTransaction(createdTransaction.txbody);
    }
  };

  const createTransaction = async (data) => {
    try {
      await init();

      const defaultFee = "ㄜ1:245";
      const defaultTimeLimit = 0;

      let result;
      if (operationType === 'create') {
        result = await create_inscription(
          data.address || "",
          data.diamonds || "",
          data.content || "",
          data.protocolCost || "0",
          data.fee || defaultFee,
          BigInt(data.transactionTimeLimit || defaultTimeLimit)
        );
      } else {
        result = await clean_inscription(
          data.address || "",
          data.diamonds || "",
          data.protocolCost || "0",
          data.fee || defaultFee,
          BigInt(data.transactionTimeLimit || defaultTimeLimit)
        );
      }

      let resultJson = JSON.parse(result);
      if (resultJson.code === 1) {
        setResult(resultJson.data);
        setTimeout(() => {
          resultRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      } else {
        alert(resultJson.message);
      }

    } catch (error) {
      console.error('交易创建失败:', error);
    }
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() => {
        alert(t('inscription.copySuccess'));
      }, (err) => {
        console.error('复制失败', err);
        fallbackCopyTextToClipboard(text);
      });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? t('inscription.copySuccess') : t('inscription.copyFailed');
      alert(msg);
    } catch (err) {
      console.error('复制失败:', err);
      alert(t('inscription.copyFailed'));
    }

    document.body.removeChild(textArea);
  };

  const signTransaction = (txbody) => {
    if (window.MoneyNex) {
      window.MoneyNex.signtx({ txbody }, (response) => {
        if (response.ret === 0) {
          console.log(response)
          setResult(prevResult => ({
            ...prevResult,
            txhash:response.hash,
            txbody: response.body,
            signedBy: response.address
          }));
        } else {
          console.error("签名失败:", response);
          alert(t('inscription.signFailed'));
        }
      });
    } else {
      console.error("MoneyNex钱包未安装或未准备就绪");
      alert(t('inscription.walletNotReady'));
    }
  };

  return (
    <div className="inscription-operations">
      <h1>{t('inscription.title')}</h1>
      <div className="operation-type-selector">
        <button
          className={operationType === 'create' ? 'active' : ''}
          onClick={() => setOperationType('create')}
        >
          {t('inscription.createInscription')}
        </button>
        <button
          className={operationType === 'clean' ? 'active' : ''}
          onClick={() => setOperationType('clean')}
        >
          {t('inscription.cleanInscription')}
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          placeholder={t('inscription.address')}
          autoComplete="off"
        />
        <textarea
          name="diamonds"
          value={formData.diamonds}
          onChange={handleInputChange}
          placeholder={t('inscription.diamonds')}
          className="diamonds-input"
          autoComplete="off"
        />
        {operationType === 'create' && (
          <textarea
            name="content"
            value={formData.content}
            onChange={handleInputChange}
            placeholder={t('inscription.content')}
            className="content-input"
            autoComplete="off"
          />
        )}
        <input
          type="text"
          name="protocolCost"
          value={formData.protocolCost}
          onChange={handleInputChange}
          placeholder={t('inscription.protocolCost')}
          autoComplete="off"
        />
        <input
          type="text"
          name="fee"
          value={formData.fee}
          onChange={handleInputChange}
          placeholder={t('inscription.fee')}
          autoComplete="off"
        />
        <input
          type="text"
          name="transactionTimeLimit"
          value={formData.transactionTimeLimit}
          onChange={handleInputChange}
          placeholder={t('inscription.transactionTimeLimit')}
          autoComplete="off"
        />
        <button type="submit">{t('inscription.submitButton')}</button>
      </form>

      {result && (
        <div className="result-container" ref={resultRef}>
          <h2>{t('inscription.successTitle')}</h2>
          <div className="button-group">
            <div className="button-row single">
              <button onClick={() => signTransaction(result.txbody)}>{t('inscription.signTransaction')}</button>
            </div>
            <div className="button-row">
              <button onClick={() => copyToClipboard(result.txbody)}>{t('inscription.copyTxbody')}</button>
              <button onClick={() => copyToClipboard(result.txhash)}>{t('inscription.copyTxhash')}</button>
            </div>
            <div className="button-row single">
              <a href="https://wallet.hacash.org" target="_blank" rel="noopener noreferrer" className="button-link">
                {t('inscription.submitToMainnet')}
              </a>
            </div>
          </div>

          {result.signedBy && (
            <p>
              <span className="label">[{t('inscription.signedBy')}]</span>
              <br/>
              <span className="value">{result.signedBy}</span>
            </p>
          )}

          <div className="transaction-info">
            <p className="fee">
              <span className="label">[{t('inscription.fee')}]</span>
              <br/>
              <span className="value">{formData.fee || 'ㄜ1:245'} ({hac_to_mei(formData.fee || '1:245')} HAC)</span>
            </p>
            <p className="txhash">
              <span className="label">[{t('inscription.txhash')}]</span>
              <br/>
              <span className="value">{result.txhash}</span>
            </p>
            <p className="txbody">
              <span className="label">[{t('inscription.txbody')}]</span>
              <br/>
              <span className="value">{result.txbody}</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default InscriptionOperations;
