import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SignTransaction.css';

function SignTransaction() {
  const { t } = useTranslation();
  const [txbody, setTxbody] = useState('');
  const [signedTxbody, setSignedTxbody] = useState('');
  const [signedBy, setSignedBy] = useState('');
  const [signatures, setSignatures] = useState(null);

  const handleInputChange = (e) => {
    setTxbody(e.target.value);
  };

  const signTransaction = () => {
    if (window.MoneyNex) {
      window.MoneyNex.signtx({txbody}, (response) => {
        if (response.ret === 0) {
          setSignedTxbody(response.body);
          setSignedBy(response.sign_data.address);
          setSignatures(response.signatures);
          console.log("签名数据:", response.signatures);
        } else {
          console.error("签名失败:", response);
          alert(t('signFailed'));
        }
      });
    } else {
      console.error("MoneyNex钱包未安装或未准备就绪");
      alert(t('walletNotReady'));
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert(t('copySuccess'));
    }, (err) => {
      console.error('复制失败', err);
      alert(t('copyFailed'));
    });
  };

  return (
    <div className="sign-transaction">
      <h1>{t('signTransactionTitle')}</h1>
      <textarea
        value={txbody}
        onChange={handleInputChange}
        placeholder={t('enterTxbody')}
      />
      <div className="button-group">
        <button onClick={signTransaction}>{t('signTransaction')}</button>
        {signedTxbody && (
          <button onClick={() => copyToClipboard(signedTxbody)}>{t('copySignedTxbody')}</button>
        )}
      </div>
      {signedTxbody && (
        <div className="signed-result">
          <h2>{t('signedResult')}</h2>
          <p>
            <span className="label">{t('signedBy')}:</span>
            <span className="value">{signedBy}</span>
          </p>
          {signatures && (
            <div className="signatures">
              <h3>{t('signatureStatus')}</h3>
              {Object.entries(signatures).map(([key, value]) => (
                <p key={key}>
                  <span className="label">{t('address')}:</span>
                  <span className="value">{value.address}</span>
                  <br />
                  <span className="label">{t('complete')}:</span>
                  <span className={`value ${value.complete ? 'signed' : 'unsigned'}`}>
                    {value.complete ? t('signed') : t('unsigned')}
                  </span>
                </p>
              ))}
            </div>
          )}
          <p>
            <span className="label">{t('signedTxbody')}:</span>
            <span className="value">{signedTxbody}</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default SignTransaction;
